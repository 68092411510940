<template>
  <div>
    <NbTablev2
      v-if="keepAlive"
      :key="tableOf"
      :tableOf="tableOf"
      :namespace="configureNameSpace"
      :ref="refTable"
      :allFields="allFields"
      :fields="fields"
      :filterOptions="filterOptions"
      :selectable="selectable"
      :showBtnOptions="showBtnOptions"
      :buttonOptions="buttonOptions"
      :optionsWidth="optionsWidth"
      @optionTook="
        $emit('optionTook', {
          command: $event,
          selectedItems: selectedTableItems,
          fields: fields,
        })
      "
      :selectedItems.sync="selectedTableItems"
      @reloadFields="fields = $event"
      @total="$emit('total', $event)"
    >
      <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
        <slot :name="slotName" v-bind="scope" />
      </template>
    </NbTablev2>
  </div>
</template>

<script>
import NbTablev2 from "@/components/tables/NbTablev2.vue";

export default {
  name: "NbMultipleTables",
  components: {
    NbTablev2,
  },
  props: {
    tabConfigurations: {
      type: Object,
      required: false,
    },
    showBtnOptions: {
      type: Boolean,
      required: false,
    },
    refTable: {
      type: String,
      required: false,
    },
    tableOf: {
      type: String,
      required: true,
    },
    rowsControl: {
      type: String,
      required: false,
    },
    namespace: {
      type: String,
      required: false,
    },
    startPerPage: {
      type: [String, Number],
      required: false,
    },
    filterOptions: {
      type: Array,
      required: false,
    },
    allFields: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      required: false,
    },
    clickable: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    deletable: {
      type: Boolean,
      required: false,
    },
    errors: {
      required: false,
    },
    shortThead: {
      type: Boolean,
      required: false,
    },
    startItems: {
      type: [Array, Object],
      required: false,
    },
    hasParentItens: {
      type: Boolean,
      default: false,
    },
    hiddeTableOptions: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: "calc(100vh - 200px)",
    },
    optionsWidth: {
      type: [Number, String],
      required: false,
    },
    buttonsTable: {
      type: [],
      default: () => [],
    },
    buttonOptions: {
      type: [],
      default: () => [],
    },
    hiddeSelectColumns: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Boolean,
      default: true,
    },
    vistaBtn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      keepAlive: false,
      fields: [],
      selectedTableItems: [],
    };
  },
  created() {
    if (this.tabConfigurations.id === "all") {
      this.keepAlive = true;
    }
  },
  methods: {
    selectedItems(event) {
      this.selectedTableItems = event;
    },
    /* configureNameSpace() {
      let finalNameSpace = this.namespace;
      if (this.tabConfigurations && this.tabConfigurations.startFilters) {
        finalNameSpace = `${this.namespace}?&${this.tabConfigurations.startFilters.key}=${this.tabConfigurations.startFilters.value}`;
      }
      return finalNameSpace;
    }, */
  },
  computed: {
    configureNameSpace() {
      let finalNameSpace = this.namespace;
      if (this.tabConfigurations && this.tabConfigurations.startFilters) {
        finalNameSpace = `${this.namespace}?&${this.tabConfigurations.startFilters.key}=${this.tabConfigurations.startFilters.value}`;
      }
      return finalNameSpace;
    },
  },
  watch: {
    /* watch attribute */
    "tabConfigurations.current": function (newVal) {
      if (!this.keepAlive && newVal === this.tabConfigurations.id) {
        this.keepAlive = true;
      }
    },
  },
};
</script>
<style scoped>
.tabs-enter-active,
.tabs-leave-active {
  transition: all 0.5s;
}
</style>
