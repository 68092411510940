<template>
  <div>
    <LoadingPage v-show="loaded" />
    <div v-show="!loaded">
      <section>
        <NbPageTitle :title="$t('dutiesAndTaxesPage.title')" />
        <NbTabs
          class="mb-3"
          :tabs="tabsOptions"
          @switchTab="switchTab($event)"
        />
        <NbMultipleTables
          v-for="tab in tabsOptions"
          :key="tab.id"
          :tabConfigurations="tab"
          v-show="tab.id === currentTable"
          :tableOf="`dutiesAndTaxes${tab.id}`"
          namespace="tracking_alert_logs"
          :refTable="`dutiesAndTaxes${tab.id}`"
          :allFields="allFields"
          :filterOptions="filterOptions"
          :selectable="true"
          :showBtnOptions="true"
          :buttonOptions="alertLogsOptions"
          @total="totalItemsPerTab[tab.id] = $event"
          @optionTook="optionTook($event)"
        >
          <template v-slot:cell(id)="data">
            <div
              class="link-1"
              data-toggle="modal"
              data-target="#modal-duties-taxes"
              @click="getTrackingAlertLogModal(data.item.id)"
            >
              {{ data.item.id }}
            </div>
          </template>

          <template v-slot:cell(volume.order.order_number)="data">
            <div
              class="link-1"
              @click="getOrderModal(data.item.volume.order.id)"
            >
              {{ data.item.volume.order.order_number }}
            </div>
          </template>
          <template v-slot:cell(real_tax)="data">
            {{ configureCyrrency(data.item.real_tax, currency) }}
          </template>

          <template v-slot:cell(status)="data">
            <NbBadge
              class="fit-content"
              :type="getStatusType(data.item.status)"
              :text="getStatusText(data.item.status)"
            />
          </template>
          <template v-slot:cell(volume.order.customer_full_name)="data">
            <div>{{ data.item.volume.order.customer_full_name }}</div>
          </template>
          <template v-slot:cell(volume.order.customer_country)="data">
            <div>{{ data.item.volume.order.customer_country }}</div>
          </template>

          <template v-slot:cell(checkpoint.title)="data">
            <div>{{ data.item.checkpoint.title }}</div>
          </template>

          <template v-slot:cell(volume.created_at)="data">
            <div>{{ `${data.item.volume.created_at}`.slice(0, 10) }}</div>
          </template>

          <template v-slot:cell(volume.order.incoterm)="data">
            <div>{{ data.item.volume.order.incoterm }}</div>
          </template>

          <template v-slot:cell(duties_status)="data">
            <div>{{ data.item.duties_status }}</div>
          </template>

          <template v-slot:cell(payment_certificate)="data">
            <a
              v-if="data.item.barcode"
              v-on:click="getPaymentPdf(data.item.id)"
            >
              <i
                class="far fa-file fa-lg text-dark"
                style="
                  font-size: 18px;
                  position: relative;
                  left: -4px;
                  bottom: 0px;
                "
              ></i>
            </a>
            <div v-else>-</div>
          </template>
          <template #cell(actions)="data">
            <NbButton variant="tertiary" @click="loadAction(data.item)">
              {{ $t("necessaryAction") }} {{ data.item.id }}
            </NbButton>
          </template>
        </NbMultipleTables>
      </section>
      <NbFooter
        :text="$t('components.footer.checkOurSupport')"
        link="https://nobordistinc.freshdesk.com/support/home"
      />

      <!-- modals -->
      <ModalDutiesAndTaxesView
        :trackingAlertLogId="currentTrackingAlertLogId"
        @reloadLogs="reloadLogs"
      />
      <ModalOrderView
        :orderId="currentOrderViewId"
        @reloadOrders="reloadOrders"
      />
    </div>
  </div>
</template>

<script>
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
//import Alert from "@/components/alerts/Alert.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbMultipleTables from "@/components/tables/NbMultipleTables.vue";
import ModalDutiesAndTaxesView from "./components/ModalDutiesAndTaxesView.vue";
//import NbButton from "@/components/buttons/NbButton.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalOrderView from "@/views/orders/components/ModalOrderView.vue";

import QuickStartService from "@/services/QuickStartService.js";
import TrackingAlertLogService from "../../services/TrackingAlertLogService";
import DownloadsService from "../../services/DownloadsService";

const downloadsService = new DownloadsService();
const trackingAlertLogService = new TrackingAlertLogService();

export default {
  name: "TrackingAlertLogsList",
  components: {
    LoadingPage,
    NbPageTitle,
    //Alert,
    NbTabs,
    NbMultipleTables,
    ModalDutiesAndTaxesView,
    //NbButton,
    NbBadge,
    NbFooter,
    ModalOrderView,
  },
  data: () => {
    return {
      currentOrderViewId: "",
      loaded: true,
      quickStartService: new QuickStartService(),
      fields: [],
      totalItemsPerTab: {
        all: -1,
        new_issue: -1,
        solved_issue: -1,
        priority_issue: -1,
        in_progress_issue: -1,
        received: -1,
        sent_again: -1,
        waiting_payment: -1,
        payment_done: -1,
        need_seller: -1,
      },
      currentTable: "all",
      selectedLogs: [],
      currency: "USD",
      currentTrackingAlertLogId: "",
      filterOptions: [
        { key: "volume_id", label: "Volume ID", type: "number" },
        { key: "tracking_code", label: "Tracking Code", type: "number" },
        { key: "tracking_rule_id", label: "Tracking Rule ID", type: "number" },
        {
          key: "duties_payment",
          label: "Duties Payment Currency",
          type: "select",
          options: ["USD", "R$"],
        },
        { key: "title", label: "Title" },
        { key: "order_number", label: "Order Number" },
        { key: "attachment", label: "Attachment", type: "boolean" },
        {
          key: "status",
          label: "Status",
          type: "select",
          options: [
            { value: "new_issue", text: "New" },
            { value: "solved_issue", text: "Solved" },
            { value: "priority_issue", text: "Priority" },
            { value: "in_progress_issue", text: "In Progress" },
            { value: "waiting_payment", text: "Waiting For Payment" },
            { value: "sent_again", text: "Sent Again" },
            { value: "payment_done", text: "Payment Done" },
            { value: "pending", text: "Payment Done" },
            { value: "paid_external", text: "Payment Done" },
            { value: "need_seller", text: "Payment Done" },
          ],
        },
        {
          key: "type",
          label: "Type",
          type: "select",
          options: [
            { value: "normal_type", text: "Normal Type" },
            { value: "payment_for_the_seller", text: "Payment for the Seller" },
            { value: "received_checkpoint", text: "Received Checkpoint" },
            {
              value: "time_between_checkpoints",
              text: "Time Between Checkpoints",
            },
            { value: "duties_payment", text: "Duties Payment" },
          ],
        },
      ],
      columns: {},
    };
  },
  beforeMount() {
    this.getQuickStart();
  },
  created() {
    setTimeout(() => {
      this.loaded = false;
    }, 1000);
  },
  methods: {
    getOrderModal(id) {
      if (id) {
        this.currentOrderViewId = id;
        this.$helpers.openModal("modal-order-view");
        return;
      }
    },
    reloadOrders() {
      this.$refs?.tableOrders?.getData();
    },
    reloadLogs() {
      this.$refs?.dutiesAndTaxesall?.getData();
    },
    getTrackingAlertLogModal(id) {
      if (id !== this.currentTrackingAlertLogId) {
        this.currentTrackingAlertLogId = id;
      }
      this.$helpers.openModal("modal-duties-taxes");
    },
    async getQuickStart() {
      const resp = await this.quickStartService.getQuicks("order");
      this.currency = resp.data.data[0].currency;
    },
    simplifyCurrency() {
      if (this.currency) {
        if (this.currency === "USD") {
          return "$";
        }
        return this.currency;
      }
      return "$";
    },
    configureCyrrency(val, currency) {
      if (typeof val === "number" || typeof val === "string") {
        return `
          ${this.simplifyCurrency(currency)}
          ${parseFloat(val).toFixed(2)}
        `;
      }
      return "-";
    },
    getStatusType(status) {
      let finalType = "warning";

      if (
        status == "solved_issue" ||
        status == "payment_done" ||
        status == "paid_external"
      ) {
        finalType = "success";

        return finalType;
      }

      if (status == "need_seller" || status == "priority_issue") {
        finalType = "danger";

        return finalType;
      }

      return finalType;
    },
    getStatusText(status) {
      let finalText = "";

      if (status == "new_issue") {
        finalText = this.$t("dutiesAndTaxesPage.status.new");
        return finalText;
      }
      if (status == "solved_issue") {
        finalText = this.$t("dutiesAndTaxesPage.status.solved");
        return finalText;
      }
      if (status == "in_progress_issue") {
        finalText = this.$t("dutiesAndTaxesPage.status.inProgress");
        return finalText;
      }
      if (status == "waiting_payment") {
        finalText = this.$t("dutiesAndTaxesPage.status.waitingForPayment");
        return finalText;
      }

      if (status == "priority_issue") {
        finalText = this.$t("dutiesAndTaxesPage.status.priority");
        return finalText;
      }
      if (status == "need_seller") {
        finalText = this.$t("dutiesAndTaxesPage.status.needSelllerAction");
        return finalText;
      }

      finalText = this.transformString(status);
      return finalText;
    },
    transformString(str) {
      let words = str.split("_");

      for (let i = 0; i < words.length; i++) {
        words[i] =
          words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
      }
      return words.join(" ");
    },
    optionTook(event) {
      this.selectedLogs = event.selectedItems;
      this.fields = event.fields;
      this[event.command]();
    },
    switchTab(tab) {
      this.currentTable = tab;
      /*
      if (this.currentTable !== tab && tab !== "all") {
        this.currentTable = tab;
        const filter = { status: tab, offset: 0 };
        this.$refs.dutiesAndTaxes.submitFilterForm(filter);
        return;
      }
      if (this.currentTable !== tab) {
        this.currentTable = tab;
        this.$refs.dutiesAndTaxes.submitFilterForm();
      }
      */
    },
    downloadFinances() {
      this.initiateFileCreation({
        data_type: "tracking_alert_logs",
        reference_ids: this.selectedLogs,
        columns: this.fields
          .map((f) => f.key)
          .filter((key) => key !== "actions"),
      });
    },
    initiateFileCreation(dataQuery) {
      downloadsService.loadProcessing(dataQuery).then(() => {
        this.$router.push("/archives");
      });
    },
    getPaymentPdf(logId) {
      trackingAlertLogService
        .downloadPaymentCerti(logId)
        .then((response) => {
          window.open(response.data.data.download_url);
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger", 3000);
          });
        });
    },
  },
  computed: {
    alertLogsOptions() {
      return [
        {
          text: this.$t("download"),
          value: "downloadFinances",
        },
      ];
    },
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("all"),
          found: this.totalItemsPerTab.all,
          current: this.currentTable,
        },
        {
          id: "new_issue",
          startFilters: { key: "status", value: "new_issue" },
          label: this.$t("dutiesAndTaxesPage.status.new"),
          found: this.totalItemsPerTab.new_issue,
          current: this.currentTable,
        },
        {
          id: "solved_issue",
          startFilters: { key: "status", value: "solved_issue" },
          label: this.$t("dutiesAndTaxesPage.status.solved"),
          found: this.totalItemsPerTab.solved_issue,
          current: this.currentTable,
        },
        {
          id: "priority_issue",
          startFilters: { key: "status", value: "priority_issue" },
          label: this.$t("dutiesAndTaxesPage.status.priority"),
          found: this.totalItemsPerTab.priority_issue,
          current: this.currentTable,
        },
        {
          id: "in_progress_issue",
          startFilters: { key: "status", value: "in_progress_issue" },
          label: this.$t("dutiesAndTaxesPage.status.inProgress"),
          found: this.totalItemsPerTab.in_progress_issue,
          current: this.currentTable,
        },
        {
          id: "waiting_payment",
          startFilters: { key: "status", value: "waiting_payment" },
          label: this.$t("dutiesAndTaxesPage.status.waitingForPayment"),
          found: this.totalItemsPerTab.waiting_payment,
          current: this.currentTable,
        },
        {
          id: "sent_again",
          startFilters: { key: "status", value: "sent_again" },
          label: this.$t("dutiesAndTaxesPage.status.sentAgain"),
          found: this.totalItemsPerTab.sent_again,
          current: this.currentTable,
        },
        {
          id: "payment_done",
          startFilters: { key: "status", value: "payment_done" },
          label: this.$t("dutiesAndTaxesPage.status.paymentDone"),
          found: this.totalItemsPerTab.payment_done,
          current: this.currentTable,
        },
        {
          id: "pending",
          startFilters: { key: "status", value: "pending" },
          label: this.$t("dutiesAndTaxesPage.status.pending"),
          found: this.totalItemsPerTab.pending,
          current: this.currentTable,
        },
        {
          id: "paid_external",
          startFilters: { key: "status", value: "paid_external" },
          label: this.$t("dutiesAndTaxesPage.status.paidExternal"),
          found: this.totalItemsPerTab.paid_external,
          current: this.currentTable,
        },
        {
          id: "need_seller",
          startFilters: { key: "status", value: "need_seller" },
          label: this.$t("dutiesAndTaxesPage.status.needSelllerAction"),
          found: this.totalItemsPerTab.need_seller,
          current: this.currentTable,
        },
      ];
    },
    allFields() {
      return [
        { key: "id", label: "ID" },
        {
          key: "volume.order.customer_full_name",
          label: this.$t("justReceiver"),
          sortable: true,
        },
        { key: "volume.order.order_number", label: this.$t("order") },
        { key: "volume.order.customer_country", label: this.$t("destination") },
        { key: "real_tax", label: this.$t("taxValue") },
        { key: "status", label: this.$t("status") },

        { key: "duties_status", label: "Duties Status" },
        { key: "checkpoint.title", label: "Tracking Status" },

        { key: "charged_amount", label: "Charged Amount" },
        { key: "volume.created_at", label: "Creation Date" },
        {
          key: "last_mile_tracking_number",
          label: "Last Mile Tracking Number",
        },
        { key: "payment_certificate", label: "Payment Certificate" },
        { key: "volume.order.incoterm", label: "incoterm" },
        /*
        {
          key: "actions",
          isCustomizable: false,
          label: this.$t("necessaryAction"),
          class: "right--20 right-sticky",
        },
        */
      ];
    },
  },
};
</script>

<style scoped>
.order-status-alerts {
  margin-bottom: 3.88rem;
}
</style>
