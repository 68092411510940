<template>
  <NbModal
    id="modal-duties-taxes"
    modalConfig="modal-dialog-centered"
    width="72rem"
  >
    <template v-slot:header>
      <div v-if="Object.keys(trackingAlertLog).length > 1" class="d-flex">
        <div class="heading-4">#{{ trackingAlertLog.id }}:</div>
        <NbPageTitle :title="transformString(trackingAlertLog.type)">
          <NbBadge
            class="fit-content ml-2"
            :type="getStatusType(trackingAlertLog.status)"
            :text="getStatusText(trackingAlertLog.status)"
          />
        </NbPageTitle>
      </div>
    </template>
    <div v-if="Object.keys(trackingAlertLog).length > 1">
      <div class="d-flex justify-content-between mb-5">
        <NbCard
          id="document-card"
          :title="$t('actions')"
          class="px-3"
          width="23.4rem"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap pt-3">
              <NbButton
                :disabled="!trackingAlertLog.barcode"
                class="w-48-5 my-1 px-2 text-left text-14-20"
                variant="secondary"
                @click="getPaymentPdf(trackingAlertLog.id)"
              >
                {{ $t("DutiesAndTaxesViewPage.paymentCertificate") }}
              </NbButton>
            </div>
          </template>
        </NbCard>
      </div>
      <NbCard
        id="general-card"
        :title="$t('DutiesAndTaxesViewPage.generalInformation')"
        class="bg-gray-05 mb-4"
      >
        <template #body>
          <div class="d-flex justify-content-between flex-wrap">
            <NbTextField
              id="order_number"
              class="w-32 mb-3 mt-2"
              :name="$t('DutiesAndTaxesViewPage.orderNumber')"
              variant="borderless-gray-10"
            >
              <div
                class="link-1"
                @click="getOrderModal(trackingAlertLog.order_id)"
              >
                {{ trackingAlertLog.order_number }}
              </div>
            </NbTextField>
            <NbTextField
              id="last_mile_tracking_number"
              class="w-32 mb-3 mt-2"
              :name="$t('DutiesAndTaxesViewPage.tracking')"
              variant="borderless-gray-10"
            >
              <span
                class="link-1"
                @click="trackSearch(trackingAlertLog.last_mile_tracking_number)"
              >
                {{ trackingAlertLog.last_mile_tracking_number }}
              </span>
            </NbTextField>
            <NbTextField
              id="incoterm"
              class="w-32 mb-3 mt-2"
              :name="$t('DutiesAndTaxesViewPage.incoterm')"
              variant="borderless-gray-10"
            >
              {{ trackingAlertLog.incoterm }}
            </NbTextField>
            <NbTextField
              id="volume_creation"
              class="w-100 mb-3 mt-2"
              :name="$t('DutiesAndTaxesViewPage.creationDate')"
              variant="borderless-gray-10"
            >
              {{
                formateDate(
                  trackingAlertLog.volume_creation,
                  trackingAlertLog.volume_creation
                )
              }}
            </NbTextField>
            <!-- 
            <NbTextField
              id="currency"
              class="w-16 mb-3 mt-2"
              :name="$t('currency')"
              variant="borderless-gray-10"
              :value="trackingAlertLog.currency"
            />
            <NbTextField
              id="message"
              class="w-100 mb-3 mt-2"
              :name="$t('DutiesAndTaxesViewPage.message')"
              variant="borderless-gray-10"
              :value="trackingAlertLog.message"
            />
            -->
          </div>
        </template>
      </NbCard>

      <div class="d-flex justify-content-between flex-wrap mb-4">
        <NbCard
          id="taxes-card"
          :title="$t('DutiesAndTaxesViewPage.taxesInformation')"
          class="bg-gray-05 w-49-5"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextField
                id="charged_amount"
                class="w-48-5 mb-3 mt-2"
                :name="$t('DutiesAndTaxesViewPage.estimatedTax')"
                variant="borderless-gray-10"
              >
                {{
                  standardizeValue(
                    trackingAlertLog.charged_amount,
                    simplifyCurrency()
                  )
                }}
              </NbTextField>
              <NbTextField
                id="real_tax"
                class="w-48-5 mb-3 mt-2"
                :name="$t('DutiesAndTaxesViewPage.realTax')"
                variant="borderless-gray-10"
              >
                {{
                  standardizeValue(
                    trackingAlertLog.real_tax,
                    simplifyCurrency()
                  )
                }}
              </NbTextField>
              <NbTextField
                id="expected_tax"
                class="w-48-5 mb-3 mt-2"
                :name="$t('DutiesAndTaxesViewPage.expectedTax')"
                variant="borderless-gray-10"
              >
                {{
                  standardizeValue(
                    trackingAlertLog.expected_tax,
                    simplifyCurrency()
                  )
                }}
              </NbTextField>
              <NbTextField
                id="products_value"
                class="w-48-5 mb-3 mt-2"
                :name="$t('productsValue')"
                variant="borderless-gray-10"
              >
                {{
                  standardizeValue(
                    trackingAlertLog.products_value,
                    simplifyCurrency()
                  )
                }}
              </NbTextField>
            </div>
          </template>
        </NbCard>
        <NbCard
          id="courier-card"
          :title="$t('DutiesAndTaxesViewPage.customerInformation')"
          class="bg-gray-05 w-49-5"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextField
                id="customer_full_name"
                class="w-100 mb-3 mt-2"
                :name="$t('DutiesAndTaxesViewPage.customerName')"
                variant="borderless-gray-10"
              >
                {{ trackingAlertLog.customer_full_name }}
              </NbTextField>
              <NbTextField
                id="customer_country"
                class="w-100 mb-3 mt-2"
                :name="$t('DutiesAndTaxesViewPage.customerCountry')"
                variant="borderless-gray-10"
              >
                {{ trackingAlertLog.customer_country }}
              </NbTextField>
            </div>
          </template>
        </NbCard>
      </div>
    </div>

    <template v-slot:footer>
      <div class="d-flex justify-content-end">
        <NbButton variant="secondary" data-dismiss="modal">
          {{ $t("close") }}
        </NbButton>
      </div>
    </template>
    <ModalOrderView
      id="duties-taxes-orderview"
      :orderId="currentOrderViewId"
      @reloadOrders="reloadOrders"
    />
  </NbModal>
</template>

<script>
import NProgress from "nprogress";
import NbModal from "@/components/modal/NbModal.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbTextField from "@/components/input/text/NbTextField.vue";
import ModalOrderView from "@/views/orders/components/ModalOrderView.vue";

import TrackingAlertLogService from "@/services/TrackingAlertLogService";

const trackingAlertLogService = new TrackingAlertLogService();

export default {
  components: {
    NbModal,
    NbPageTitle,
    NbButton,
    NbBadge,
    NbCard,
    NbTextField,
    ModalOrderView,
  },
  name: "ModalDutiesAndTaxes",
  props: {
    trackingAlertLogId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      currentOrderViewId: "",
      loading: true,
      trackingBaseUrl: process.env.VUE_APP_TRACKING_BASE_URL,
      trackingAlertLog: {
        attachment: null,
        customer_country: null,
        customer_full_name: null,
        barcode: null,
        charged_amount: null,
        checkpoint_id: null,
        comment: null,
        created_at: "",
        currency: "",
        date_iso: null,
        expected_tax: null,
        freight_value: null,
        incoterm: "",
        last_mile_number: null,
        last_mile_tracking_number: null,
        message: null,
        order_data: {},
        order_id: null,
        order_number: "",
        products_value: "",
        real_tax: null,
        status: "",
        tax_id: "",
        title: null,
        type: "",
        volume_creation: null,
      },
    };
  },
  computed: {},
  watch: {
    trackingAlertLogId() {
      this.getData();
    },
  },
  methods: {
    getOrderModal(id) {
      if (id) {
        this.currentOrderViewId = id;
        this.$helpers.openModal("duties-taxes-orderview");
        return;
      }
    },
    reloadOrders() {
      this.$refs?.tableOrders?.getData();
    },
    getData() {
      trackingAlertLogService
        .getLog(this.trackingAlertLogId)
        .then((response) => {
          const log = response.data.data;
          this.trackingAlertLog = log;
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.$router.push("/");
          }
          this.$helpers.toast(error.message, "danger");
          NProgress.done();
        });
    },
    getPaymentPdf(logId) {
      trackingAlertLogService
        .downloadPaymentCerti(logId)
        .then((response) => {
          window.open(response.data.data.download_url);
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger", 3000);
          });
        });
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      if (date && time) {
        const data = new Date(date);
        const hours = new Date(time);
        const formatedDate =
          this.addZero(
            data.getDate().toString() +
              "/" +
              this.addZero(data.getMonth() + 1).toString() +
              "/" +
              data.getFullYear()
          ) +
          "  " +
          this.addZero(hours.getHours()) +
          ":" +
          this.addZero(hours.getMinutes()) +
          ":" +
          this.addZero(hours.getSeconds());
        return formatedDate;
      }
      return "-";
    },
    trackSearch(traking_number) {
      if (traking_number) {
        const lastCharOfTrackingBaseUrl = this.trackingBaseUrl.slice(-1);
        //check the last trackingBaseUrl is a "/" and remove it to avoid router bugs like (...)/trackings/tracking making it like (...)/trackings?trackingNumber=tracking_number
        if (lastCharOfTrackingBaseUrl === "/") {
          this.trackingBaseUrl = this.trackingBaseUrl.slice(0, -1);
        }

        window.open(this.trackingBaseUrl + "?trackingNumber=" + traking_number);
        return;
      }
      location.replace(this.trackingBaseUrl + "404");
    },
    simplifyCurrency() {
      if (this.trackingAlertLog.currency === "USD") {
        return "$";
      } else {
        return this.trackingAlertLog.currency;
      }
    },
    standardizeValue(val, currency) {
      if (typeof val === "number" || typeof val === "string") {
        return currency + parseFloat(val).toFixed(2);
      }
      return "-";
    },
    getStatusType(status) {
      let finalType = "warning";

      if (
        status == "solved_issue" ||
        status == "payment_done" ||
        status == "paid_external"
      ) {
        finalType = "success";

        return finalType;
      }

      if (status == "need_seller" || status == "priority_issue") {
        finalType = "danger";

        return finalType;
      }

      return finalType;
    },
    getStatusText(status) {
      let finalText = "";

      if (status == "new_issue") {
        finalText = this.$t("dutiesAndTaxesPage.status.new");
        return finalText;
      }
      if (status == "solved_issue") {
        finalText = this.$t("dutiesAndTaxesPage.status.solved");
        return finalText;
      }
      if (status == "in_progress_issue") {
        finalText = this.$t("dutiesAndTaxesPage.status.inProgress");
        return finalText;
      }
      if (status == "waiting_payment") {
        finalText = this.$t("dutiesAndTaxesPage.status.waitingForPayment");
        return finalText;
      }

      if (status == "priority_issue") {
        finalText = this.$t("dutiesAndTaxesPage.status.priority");
        return finalText;
      }
      if (status == "need_seller") {
        finalText = this.$t("dutiesAndTaxesPage.status.needSelllerAction");
        return finalText;
      }

      finalText = this.transformString(status);
      return finalText;
    },
    transformString(str) {
      let words = str.split("_");

      for (let i = 0; i < words.length; i++) {
        words[i] =
          words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
      }
      return words.join(" ");
    },
  },
};
</script>

<style scoped></style>
