import HttpService from "./HttpService";

export default class QuickstartService {
  constructor() {
    this.httpService = HttpService;
  }

  getQuicks(action_type) {
    return this.httpService.get("/v1/quick_start?action_type=" + action_type);
  }
  getQuick(id) {
    return this.httpService.get("/v1/quick_start/" + id);
  }
  createQuick(data) {
    return this.httpService.post("/v1/quick_start", data);
  }
  updateQuick(id, data) {
    return this.httpService.put("/v1/quick_start/" + id, data);
  }
  deleteQuick(id) {
    return this.httpService.delete("/v1/quick_start/" + id);
  }
}
